<script>
import APIUser from '@app/services/API/User';

export default {
  name: 'Activation',
  head: {
    title: 'Activation',
  },
  data() {
    return {
      isLinkValid: true,
    };
  },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    const { query } = this.$route;

    if (!query.email || !query.t) {
      this.isLinkValid = false;
      return;
    }

    APIUser.verifyEmail(query)
      .then(() => {
        if (this.authUser) {
          this.$store.dispatch('auth/fetch');
        }

        this.$buefy.dialog.alert(
          'Votre compte est activé ! Vous pouvez désormais vous connecter à votre espace.',
        );
      })
      .catch((error) => this.$errorHandlers.axios(error))
      .finally(() => this.$router.replace({ name: this.authUser ? 'home' : 'login' }));
  },
};
</script>

<template>
  <section class="page section content is-large has-text-centered pb-32">
    <h1 class="is-size-4-touch">
      Activation de votre compte
    </h1>
    <div v-if="isLinkValid" class="page_loading mt-10 mx-auto">
      <b-skeleton :count="3" size="is-large" />
    </div>
    <div class="is-size-6-touch" v-else>
      <p>
        Ce lien d'activation n'existe pas. <br>
        Avez-vous bien copié le lien d'activation qui vous a été envoyé par email ?
      </p>

      <p>
        En cas de soucis, vous pouvez nous contactez par courriel à l'adresse
        <strong>allo@teachizy.fr</strong>,
        <br>
        en nous précisant l'adresse email associée à votre compte.
      </p>

      <p>
        <b-button
          type="is-primary"
          tag="router-link"
          :to="{name: authUser ? 'home' : 'login'}"
          replace>
          Retourner à l'accueil
        </b-button>
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page {
  &_loading {
    width: 50%;
  }
}
</style>
